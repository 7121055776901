
import Vue from 'vue';
import { invoiceType } from '@/application/enums/invoiceType';
import { invoiceStatus } from '@/application/enums/invoiceStatus';
import KDateField from '@/components/crud/fields/KDateField.vue';
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KSidebar from '@/components/layout/KSidebar.vue';
import EmployerAutocomplete from '@/modules/employer/components/EmployerAutocomplete.vue';
import CandidateAutocomplete from '@/modules/candidate/components/CandidateAutocomplete.vue';
import KAutocomplete from '@/components/crud/fields/KAutocomplete.vue';
import { getTranslatedEnumOptions } from '@/application/helpers/enum.js';
import { cleanUpFilters } from '@/application/helpers/cleanUpFilters';
import KCheckboxGroup from '@/components/crud/fields/KCheckboxGroup.vue';

interface componentData {
  form?: {
    // @TODO use this when implementing boolean filters
    // showHasConceptTimeRegistrations?: boolean,
    // showHasUnapprovedTimeRegistrations?: boolean,
    employerId?: number,
    candidateIds?: number[],
    invoiceTypeIds?: number[],
    invoiceStatusIds: number[],
    periodFrom?: string,
    periodTo?: string
  };
}

interface keyValuePair {
  text: string,
  value: number
}

export default Vue.extend({
  name: 'ConceptInvoicesFilter',
  components: {
    KCheckboxGroup,
    KAutocomplete,
    CandidateAutocomplete,
    KSidebar,
    KFieldGroup,
    KDateField,
    EmployerAutocomplete,
  },
  inheritAttrs: false,
  props: {
    filters: {
      type: Object,
    },
  },
  data: (): componentData => ({
    form: {
      invoiceStatusIds: [invoiceStatus.CONCEPT],
    },
  }),
  computed: {
    invoiceTypes(): keyValuePair[] {
      return getTranslatedEnumOptions(invoiceType, 'invoice.invoiceType') as keyValuePair[];
    },
    invoiceStatuses(): keyValuePair[] {
      return [
        {
          text: this.$t('invoice.filterFields.finishedPeriod'),
          value: invoiceStatus.CONCEPT,
        },
        {
          text: this.$t('invoice.filterFields.unfinishedPeriod'),
          value: invoiceStatus.PREPARING,
        },
      ] as keyValuePair[];
    },
  },
  watch: {
    filters: {
      handler(values): void {
        if (values.candidateIds && !Array.isArray(values.candidateIds)) {
          values.candidateIds = [values.candidateIds];
        }
        if (values.invoiceTypeIds && !Array.isArray(values.invoiceTypeIds)) {
          values.invoiceTypeIds = [values.invoiceTypeIds];
        }
        this.form = { ...this.form, ...values };
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    handleFilterClick(): void {
      if (this.form) {
        cleanUpFilters(this.form);
      }
      this.$emit('update:filters', this.form);
      this.$emit('input', false);
    },
    handleCleanFilter(): void {
      if (this.form) {
        this.form = {
          invoiceStatusIds: [invoiceStatus.CONCEPT],
        };
      }
      this.$emit('update:filters', this.form);
    },
  },
});
