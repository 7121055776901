<template>
  <KCrudLayout
    :filters.sync="filters"
    :search.sync="searchQuery"
  >
    <template #header>
      <k-title>{{ $tc('invoice.externalInvoices.title', 2) }}</k-title>
    </template>
    <template #filters="{ attrs, on }">
      <ConceptInvoicesFilter
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <template #view.list>
      <k-crud-table
        ref="table"
        :headers="crudHeaders"
        :index-request="index"
        language-prefix="invoice.fields"
        :params="filters"
        :search="searchQuery"
        resource="invoice"
      >
        <template #item.warning="{ item }">
          <v-tooltip
            v-if="item.hasUnapprovedTimeRegistrations || item.hasConceptTimeRegistrations"
            top
          >
            <template #activator="{ on }">
              <v-icon
                color="warning"
                v-on="on"
              >
                $triangle
              </v-icon>
            </template>
            <div v-if="item.hasUnapprovedTimeRegistrations">
              {{ $t(`invoice.messages.warnings.hasUnapprovedTimeRegistrations`) }}
            </div>
            <div v-if="item.hasConceptTimeRegistrations">
              {{ $t(`invoice.messages.warnings.hasConceptTimeRegistrations`) }}
            </div>
          </v-tooltip>
        </template>
        <template #item.invoiceTypeId="{ item: { invoiceTypeId } }">
          {{ $t(`invoice.invoiceType.${getEnumKeyByValue(invoiceType, invoiceTypeId)}`) }}
        </template>
        <template #item.amount="{ item: { amount } }">
          {{ $n((parseInt(amount) / 100),'currency') }}
        </template>
        <template #item.week="{ item: { month, week } }">
          {{ month ? $tc(`global.shortMonth.${month}`) : '' }}
          {{ week ? $tc('global.week') + ' ' + week : '' }}
        </template>
        <template #actions="{ item }">
          <k-btn
            v-if="!item.hasUnapprovedTimeRegistrations && !item.hasConceptTimeRegistrations"
            @click.stop="handleCreateInvoice(item)"
          >
            {{ $t('actions.viewResource', { resource: $tc('invoice.title', 1) }) }}
          </k-btn>
        </template>
      </k-crud-table>
    </template>
  </KCrudLayout>
</template>

<script lang="js">
import KCrudTable from '@/components/crud/KCrudTable.old.vue';
import eventBus from '@/application/eventBus.ts';
import KTitle from '@/components/layout/KTitle.vue';
import { invoiceType } from '@/application/enums/invoiceType';
import { externalInvoicesIndex } from '@/modules/invoice/api/overview.js';
import KBtn from '@/components/KButton.vue';
import KCrudLayout from '@/components/layout/KCrudLayout.vue';
import { getEnumKeyByValue } from '@/application/helpers/enum.js';
import ConceptInvoicesFilter from '@/modules/invoice/components/ConceptInvoicesFilter.vue';
import querySyncMixin from '@/application/mixins/querySyncMixin.js';

export default {
  name: 'ExternalInvoicesTable',
  components: {
    ConceptInvoicesFilter,
    KCrudLayout,
    KBtn,
    KTitle,
    KCrudTable,
  },
  mixins: [querySyncMixin],
  data: () => ({
    // todo uncomment when implementing showFinishedPeriod filter
    //filters: { showFinishedPeriod: true },
    filters: {},
    searchQuery: '',
  }),
  computed: {
    invoiceType: () => invoiceType,
    crudHeaders() {
      return [
        {
          value: 'warning',
          text: '',
          sortable: false,
          width: '1%',
        }, {
          value: 'sender',
          language: 'invoice.crudHeaders.sender',
        }, {
          value: 'receiver',
          language: 'invoice.crudHeaders.receiver',
        }, {
          value: 'invoiceTypeId',
          language: 'invoice.crudHeaders.invoiceType',
        }, {
          align: 'end',
          value: 'amount',
          language: 'invoice.crudHeaders.amount',
        }, {
          value: 'week',
          language: 'invoice.crudHeaders.date',
        }];
    },
  },
  created() {
    eventBus.$emit('setBreadcrumbs', [{
      exact: true,
      to: { name: 'invoice.externalInvoices.index' },
      text: this.$tc('invoice.externalInvoices.title', 2),
    }]);
  },
  methods: {
    getEnumKeyByValue: getEnumKeyByValue,

    handleCreateInvoice(item) {
      this.$router.push({ name: 'invoice.externalInvoices.view', params: { id: item.id } });
    },
    index(page, perPage, search, sortBy, descending, params) {
      if (sortBy === 'week') sortBy = 'date';

      return externalInvoicesIndex(page, perPage, search, sortBy, descending, params);
    },
  },
};
</script>
